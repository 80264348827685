body[data-theme="dark"] {
  --input-range-thumb-color-green: mediumseagreen;
  --input-range-thumb-color-lime: yellowgreen;
  --input-range-thumb-color-yellow: gold;
  --input-range-thumb-color-red: tomato;
  --input-range-thumb-color-orange: orange;
  --input-range-track-color: rgb(96, 98, 104);
}
body[data-theme="light"] {
  --input-range-thumb-color-green: mediumseagreen;
  --input-range-thumb-color-lime: yellowgreen;
  --input-range-thumb-color-yellow: gold;
  --input-range-thumb-color-red: tomato;
  --input-range-thumb-color-orange: orange;
  --input-range-track-color: rgb(201, 201, 201);
}

input[type="range"] {
  height: 36px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  transition: 0.2s;
  background: var(--input-range-track-color);
  border-radius: 5px;
}
input[type="range"]::-webkit-slider-thumb {
  height: 30px;
  width: 25px;
  border-radius: 3px;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"].green::-webkit-slider-thumb {
  background: var(--input-range-thumb-color-green);
}
input[type="range"].lime::-webkit-slider-thumb {
  background: var(--input-range-thumb-color-lime);
}
input[type="range"].yellow::-webkit-slider-thumb {
  background: var(--input-range-thumb-color-yellow);
}
input[type="range"].orange::-webkit-slider-thumb {
  background: var(--input-range-thumb-color-orange);
}
input[type="range"].red::-webkit-slider-thumb {
  background: var(--input-range-thumb-color-red);
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--input-range-track-color);
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  transition: 0.2s;
  background: var(--input-range-track-color);
  border-radius: 5px;
}
input[type="range"]::-moz-range-thumb {
  height: 30px;
  width: 25px;
  border-radius: 3px;
  background: #3fba75;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  transition: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: var(--input-range-track-color);
  border-radius: 10px;
}
input[type="range"]::-ms-fill-upper {
  background: var(--input-range-track-color);
  border-radius: 10px;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  height: 30px;
  width: 25px;
  border-radius: 3px;
  background: #3fba75;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: var(--input-range-track-color);
}
input[type="range"]:focus::-ms-fill-upper {
  background: var(--input-range-track-color);
}
